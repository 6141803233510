import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {updateObject} from '../../utils/reducerUtils';

import Countdown from '../../components/Countdown';
import strings from '../../utils/strings';
import AudioPlayer from '../../components/AudioPlayer';
import wordAudioDe from '../../assets/testdata/child/de/ordfortaelse-audio';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeHigh} from '@fortawesome/free-solid-svg-icons';
import {VARIATION} from '../../constants';
let initialAudioIndex = 0;
class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		const words = require(`../../assets/testdata/${variation}/${language}/ordforstaelse-data.json`);
		this.words = words;
		this.audio = wordAudioDe;
		const results = {};

		for (const question of this.words) {
			results[question.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
			showWordAudio: false,
			currentClickedIndex: null,
			showChoiceAudio: false,
			currentChoiceIndex: null,
			showQuestionPlayButton: true,
			showChoicePlayButton: true,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep,refs} = this.props;

		const {timeRanOut, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		const minutes = 10;
		const minutesInHour = 60;

		return (
			<div className="lit-test lit-ordforstaelse">
				<div className="lit-infobar">
					<Countdown
						duration={minutes * minutesInHour}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>
				<p>{strings.chooseSynonym}</p>
				<div
					className={classNames({
						'lit-questions': true,
						'time-ran-out': timeRanOut,
					})}
				>
					{this.words.map((question,mainIndex) => {
						const {id, choices} = question;
						initialAudioIndex = initialAudioIndex + 1;
						const currentQuestionValue = results[id];
						const audioSource = this.audio[mainIndex].mainWord;
						return (
							<div className="lit-question" key={id}>
								<div className="question">
								<span className="lit-input__label lit-input__label--count">
										{id}
									</span>

									<span
										style={{
											display: 'flex',
										}}
									>
										<p className="question__text">
											{this.state.showWordAudio &&
												this.state
													.currentClickedIndex ===
													mainIndex && (
														<span style={{marginLeft:'-5px'}}>
													<AudioPlayer
														
														autoplay
														inline
														id={initialAudioIndex}
														refs={refs}
														src={audioSource}
														onComplete={() => {
															this.setState({
																showQuestionPlayButton: true,
																currentClickedIndex:
																	null,
																showChoicePlayButton: true,
															});
														}}
														onPause={() => {
															this.setState({
																showQuestionPlayButton: true,
																currentClickedIndex:
																	null,
																showChoicePlayButton: true,
															});
														}}
													/>
													</span>
												)}
											<span>
												{this.state
													.showQuestionPlayButton && (
													<FontAwesomeIcon
														size="lg"
														className="lit-play-button"
														icon={faVolumeHigh}
														onClick={() => {
															this.setState({
																showWordAudio: true,
																showQuestionPlayButton: false,
																currentClickedIndex:
																	mainIndex,
																showChoicePlayButton: false,
															});
														}}
													/>
												)}
												{!this.state
													.showQuestionPlayButton && 
													this.state
													.currentClickedIndex !==
													mainIndex && (
													<FontAwesomeIcon
														size="lg"
														className="lit-play-button-disabled"
														icon={faVolumeHigh}
														
													/>
												)}
											</span>
											<span style={{marginLeft:'3px'}}>{question.word}</span>
										</p>
									</span>
								</div>
								<div className="choices">
									{choices.map((choice) => {
										const choiceLetter = choice.letter;
										const idChoice = id + choiceLetter;
										return (
											<span
												className="lit-choice"
												key={idChoice}
											>
												<input
													checked={
														currentQuestionValue ===
														choiceLetter
													}
													className="lit-choice__field"
													id={idChoice}
													onChange={() => {
														if (timeRanOut) {
															return;
														}
														this.setResults(
															id,
															choiceLetter
														);
													}}
													type="radio"
												/>
												<label
													className="lit-choice__label"
													htmlFor={idChoice}
												>
													<div className="checkbox" />
													{choice.choice}
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
