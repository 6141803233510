import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
import classNames from 'classnames';
import {updateObject} from '../../utils/reducerUtils';

import AudioPlayer from '../../components/AudioPlayer';
import * as deTutorial from '../../assets/testdata/child/de/ordfortaelse-tutorial';
const tutorialObj = {
	de: deTutorial
};
let count = 1;
class Test extends React.Component {
	constructor() {
		super();
		this.tutorial = tutorialObj[strings.getLanguage()];
		this.tutorialAssignment = this.tutorial.tutorialAssignment;
		this.assignments = this.tutorial.assignments;
		this.starterTestenSound = this.tutorial.starterTestenSound;
		console.log('this.assignments',this.assignments);
		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			hasPlayedFirstAudio: false,
			results,
			counter: 1,
			choiceId: null,
			choiceValue: null,
			limit: 6,
			hideComment: false,
			showTestPanel: true,
			nextTutorial: false,
		};
		this.singleAssignment = {
			1: this.tutorial.assignment1,
			2: this.tutorial.assignment2,
			3: this.tutorial.assignment3,
			4: this.tutorial.assignment4,
		};

		this.audioRefs = {};
	}
	setResults(id, value /* remark */) {
		const {results} = this.state;
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			hideComment: false,
			choiceId: id,
			choiceValue: value,
			/* nextTutorial:remark */
		});
		this.scrollToTop();
	}
	updateCount() {
		const {results} = this.state;
		this.setState(
			{
				results: updateObject(results, {
					[this.state.choiceId]: this.state.choiceValue,
				}),
			},
			() => {
				this.setState({
					counter: count,
					hideComment: true,
					showTestPanel: false,
					nextTutorial: true,
				});
				
			}
		);

		/* this.scrollToTop(); */
	}

	scrollToTop = () => {
		window.scrollTo({
			top: 169,
			behavior: 'smooth',
		});
	};
	render() {
		const {onNextStep} = this.props;
		this.scrollToTop();

		const {hasPlayedFirstAudio, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);

		const isNextButtonVisible = count === 4;
	
		return (
			<div className="lit-test lit-ordforstaelse">
				<div
					className={classNames({
						'lit-questions': true,
						
					})}
				>
				
					{this.singleAssignment[this.state.counter].map(
						(assignment) => {
							const {id, choices} = assignment;

							const currentAssignmentValue = results[id];
							let comment = null;
							for (let choice of choices) {
								if (id === 7) break;
								if (
									currentAssignmentValue === choice.word &&
									!this.state.hideComment
								) {
									comment = (
										<p className="instruction">
											<AudioPlayer
												autoplay
												inline
												// eslint-disable-next-line no-loop-func
												onComplete={() => {
													if (choice.remark) {
														count = count + 1;
														this.updateCount();
													}
												}}
												ref={(ref) => {
													this.narDuStarterAudioPlayer = ref;
												}}
												refs={this.audioRefs}
												src={choice.sound}
											/>
											{choice.comment}
										</p>
									);
								}
							}
							if (this.state.showTestPanel && count < 4) {
								return (
									<div className="lit-question" key={id}>
										<br/>
										<div className="question">
										<span className="lit-input__label lit-input__label--count">
										{id}
									</span>
										<h4 className="question__text">
												<AudioPlayer
													inline
													autoplay
													ref={(ref) => {
														this.narDuStarterAudioPlayer = ref;
													}}
													refs={this.audioRefs}
													src={assignment.mainSound}
												/>
												{assignment.text}
											</h4>
										</div>
											<div className="choices">
												{choices.map((choice, i) => {
													const idWord =
														i + choice.word;
													return (
														<span
														className="lit-choice"
															key={idWord}
														>
															<input
																checked={
																	currentAssignmentValue ===
																	choice.word
																}
																className="lit-choice__field"
																id={idWord}
																onChange={() =>
																	this.setResults(
																		id,
																		choice.word
																	)
																}
																type="radio"
															/>
															<label
																	className="lit-choice__label"
																htmlFor={idWord}
															>
																<div className="checkbox" />
																{/* {choice.word} */}
																<span>
																	<p>{choice.word}</p>
																</span>
															</label>
														</span>
													);
												})}
											</div>
									
												<br/>
										{comment}
									</div>
								);
							}
						}
					)}
		
				</div>
				{isNextButtonVisible && (
					<>
					<p className="instruction">
					<AudioPlayer
						inline
						autoplay
						ref={(ref) => {
							this.narDuStarterAudioPlayer = ref;
						}}
						refs={this.audioRefs}
						src={this.starterTestenSound}
					/>
					{strings.tulleordStarterTesten}
				</p>
					<button
						className="lit-btn bg-primary"
						onClick={() => {
							onNextStep(results);
							
						}}
					>
						{strings.start}
					</button>
					</>
				)}
				{this.state.nextTutorial && count < 4 && (
					<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								showTestPanel: true,
								nextTutorial: false,
							});
							
						}}
					>
						{'Weiter'}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
