import audio1 from '../../../../assets/sounds/child/de/diktat/03.mp3';
import audio2 from '../../../../assets/sounds/child/de/diktat/04.mp3';
import audio3 from '../../../../assets/sounds/child/de/diktat/05.mp3';
import audio4 from '../../../../assets/sounds/child/de/diktat/06.mp3';
import audio5 from '../../../../assets/sounds/child/de/diktat/07.mp3';
import audio6 from '../../../../assets/sounds/child/de/diktat/08.mp3';
import audio7 from '../../../../assets/sounds/child/de/diktat/09.mp3';
import audio8 from '../../../../assets/sounds/child/de/diktat/10.mp3';
import audio9 from '../../../../assets/sounds/child/de/diktat/11.mp3';
import audio10 from '../../../../assets/sounds/child/de/diktat/12.mp3';
import audio11 from '../../../../assets/sounds/child/de/diktat/13.mp3';
import audio12 from '../../../../assets/sounds/child/de/diktat/14.mp3';
import audio13 from '../../../../assets/sounds/child/de/diktat/15.mp3';
import audio14 from '../../../../assets/sounds/child/de/diktat/16.mp3';
import audio15 from '../../../../assets/sounds/child/de/diktat/17.mp3';
import audio16 from '../../../../assets/sounds/child/de/diktat/18.mp3';
import audio17 from '../../../../assets/sounds/child/de/diktat/19.mp3';
import audio18 from '../../../../assets/sounds/child/de/diktat/20.mp3';
import audio19 from '../../../../assets/sounds/child/de/diktat/21.mp3';
import audio20 from '../../../../assets/sounds/child/de/diktat/22.mp3';


import mid1 from '../../../../assets/sounds/child/de/diktat/middleGrades/01.mp3';
import mid2 from '../../../../assets/sounds/child/de/diktat/middleGrades/02.mp3';
import mid3 from '../../../../assets/sounds/child/de/diktat/middleGrades/03.mp3';
import mid4 from '../../../../assets/sounds/child/de/diktat/middleGrades/04.mp3';
import mid5 from '../../../../assets/sounds/child/de/diktat/middleGrades/05.mp3';
import mid6 from '../../../../assets/sounds/child/de/diktat/middleGrades/06.mp3';
import mid7 from '../../../../assets/sounds/child/de/diktat/middleGrades/07.mp3';
import mid8 from '../../../../assets/sounds/child/de/diktat/middleGrades/08.mp3';
import mid9 from '../../../../assets/sounds/child/de/diktat/middleGrades/09.mp3';
import mid10 from '../../../../assets/sounds/child/de/diktat/middleGrades/10.mp3';
import mid11 from '../../../../assets/sounds/child/de/diktat/middleGrades/11.mp3';
import mid12 from '../../../../assets/sounds/child/de/diktat/middleGrades/12.mp3';
import mid13 from '../../../../assets/sounds/child/de/diktat/middleGrades/13.mp3';
import mid14 from '../../../../assets/sounds/child/de/diktat/middleGrades/14.mp3';
import mid15 from '../../../../assets/sounds/child/de/diktat/middleGrades/15.mp3';
import mid16 from '../../../../assets/sounds/child/de/diktat/middleGrades/16.mp3';
import mid17 from '../../../../assets/sounds/child/de/diktat/middleGrades/17.mp3';
import mid18 from '../../../../assets/sounds/child/de/diktat/middleGrades/18.mp3';
import mid19 from '../../../../assets/sounds/child/de/diktat/middleGrades/19.mp3';
import mid20 from '../../../../assets/sounds/child/de/diktat/middleGrades/20.mp3';
import mid21 from '../../../../assets/sounds/child/de/diktat/middleGrades/21.mp3';
import mid22 from '../../../../assets/sounds/child/de/diktat/middleGrades/22.mp3';
import mid23 from '../../../../assets/sounds/child/de/diktat/middleGrades/23.mp3';
import mid24 from '../../../../assets/sounds/child/de/diktat/middleGrades/24.mp3';
import mid25 from '../../../../assets/sounds/child/de/diktat/middleGrades/25.mp3';

import upper1 from '../../../../assets/sounds/child/de/diktat/upperGrades/01.mp3';
import upper2 from '../../../../assets/sounds/child/de/diktat/upperGrades/02.mp3';
import upper3 from '../../../../assets/sounds/child/de/diktat/upperGrades/03.mp3';
import upper4 from '../../../../assets/sounds/child/de/diktat/upperGrades/04.mp3';
import upper5 from '../../../../assets/sounds/child/de/diktat/upperGrades/05.mp3';
import upper6 from '../../../../assets/sounds/child/de/diktat/upperGrades/06.mp3';
import upper7 from '../../../../assets/sounds/child/de/diktat/upperGrades/07.mp3';
import upper8 from '../../../../assets/sounds/child/de/diktat/upperGrades/08.mp3';
import upper9 from '../../../../assets/sounds/child/de/diktat/upperGrades/09.mp3';
import upper10 from '../../../../assets/sounds/child/de/diktat/upperGrades/10.mp3';
import upper11 from '../../../../assets/sounds/child/de/diktat/upperGrades/11.mp3';
import upper12 from '../../../../assets/sounds/child/de/diktat/upperGrades/12.mp3';
import upper13 from '../../../../assets/sounds/child/de/diktat/upperGrades/13.mp3';
import upper14 from '../../../../assets/sounds/child/de/diktat/upperGrades/14.mp3';
import upper15 from '../../../../assets/sounds/child/de/diktat/upperGrades/15.mp3';
import upper16 from '../../../../assets/sounds/child/de/diktat/upperGrades/16.mp3';
import upper17 from '../../../../assets/sounds/child/de/diktat/upperGrades/17.mp3';
import upper18 from '../../../../assets/sounds/child/de/diktat/upperGrades/18.mp3';
import upper19 from '../../../../assets/sounds/child/de/diktat/upperGrades/19.mp3';
import upper20 from '../../../../assets/sounds/child/de/diktat/upperGrades/20.mp3';
import upper21 from '../../../../assets/sounds/child/de/diktat/upperGrades/21.mp3';
import upper22 from '../../../../assets/sounds/child/de/diktat/upperGrades/22.mp3';
import upper23 from '../../../../assets/sounds/child/de/diktat/upperGrades/23.mp3';
import upper24 from '../../../../assets/sounds/child/de/diktat/upperGrades/24.mp3';
import upper25 from '../../../../assets/sounds/child/de/diktat/upperGrades/25.mp3';
export const audioFiles_grade2 = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,

};

export const audioFiles_grade3 = {
	1: mid1,
	2: mid2,
	3: mid3,
	4: mid4,
	5: mid5,
	6: mid6,
	7: mid7,
	8: mid8,
	9: mid9,
	10: mid10,
	11: mid11,
	12: mid12,
	13: mid13,
	14: mid14,
	15: mid15,
	16: mid16,
	17: mid17,
	18: mid18,
	19: mid19,
	20: mid20,
	21: mid21,
	22: mid22,
	23: mid23,
	24: mid24,
	25: mid25,

};

export const audioFiles_grade4 = audioFiles_grade3;

export const audioFiles_grade5 = {
	1: upper1,
	2: upper2,
	3: upper3,
	4: upper4,
	5: upper5,
	6: upper6,
	7: upper7,
	8: upper8,
	9: upper9,
	10: upper10,
	11: upper11,
	12: upper12,
	13: upper13,
	14: upper14,
	15: upper15,
	16: upper16,
	17: upper17,
	18: upper18,
	19: upper19,
	20: upper20,
	21: upper21,
	22: upper22,
	23: upper23,
	24: upper24,
	25: upper25,

};

export const audioFiles_grade6 = audioFiles_grade5;
export const audioFiles_grade7 = audioFiles_grade5;
export const audioFiles_grade8 = audioFiles_grade5;