import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {setTestCandidateGrade} from '../../actions/index';
import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import CustomLogin from '../CustomLogin';
import {initAuthAction} from '../../actions/index';

import {authCallback} from '../../utils/bankIdLogin';
import strings from '../../utils/strings';
import {
	BANK_ID,
	BANK_ID_MOBILE,
	FEIDE,
	PINCODE,
	IS_NORMING_VERSION,
	LANGUAGE,
} from '../../constants';

import loginNoAuth from '../../api/loginNoAuth';
import verifyNoAuthDe from '../../api/verifyNoAuthDe';

const parsedSearch = queryString.parse(window.location.search);
const search = parsedSearch;
const params = new URLSearchParams(search);

const testKey = parsedSearch.key;
const id = params.get('id');
/* const flag = JJiisTezRREXXDSSERllliOR321Frrsaas */

const loginMethods = {
	no: [BANK_ID, BANK_ID_MOBILE, FEIDE, PINCODE],
	se: [PINCODE],
	de: [PINCODE],
};

class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoginVisible: true,
			pincodeLogin: false,
			email: null,
			pinCode: null,
			childSelfStarting: null,
			showAltLogin:
				/* id === 'JJiisTezRREXXDSSERllliOR321Frrsaas' || */
				id === 'child-exp-oms-test' ? true : false,
			altError: null,
		};
	}

	componentDidMount() {
		window.localStorage.removeItem('authtoken-screening');
		if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		}

		const language = strings.getLanguage();
		if (language === 'de' || language === 'se') {
			verifyNoAuthDe(testKey)
				.then((results) => {
					authCallback({data: JSON.stringify(results)});
					return true;
				})
				.catch(() => {
					this.setState({
						altError: 'Fehler: Ungültiger Testcode',
					});
				});
		}
	}

	initializeAltLogin = () => {
		this.setState({
			showAltLogin: true,
		});
	};
	onAltLoginFieldChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	submitAltLogin = (e) => {
		e.preventDefault();
		const {firstName, lastName} = this.state;
		const data = {testKey, firstName, lastName};

		loginNoAuth(data)
			.then((results) => {
				authCallback({data: JSON.stringify(results)});
			})
			.catch(() => {
				this.setState({
					altError: 'Fehler: Ungültiger Testcode',
				});
			});
	};

	render() {
		const {isLoginVisible, showAltLogin, altError} = this.state;

		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;

		const showIframe = isInit && iframeUrl && !showAltLogin;
		const showError = isInit && error && !showAltLogin;
		const showLoginButtons = !isInit && !showAltLogin;
		return (
			<div className="Login">
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					{strings.getLanguage() !== 'de' &&
						strings.getLanguage() !== 'se' && <h2>Innlogging</h2>}

					{!IS_NORMING_VERSION && (
						<p>
							Du må logge inn før du kan starte testen. Velg om du
							vil logge inn med vanlig BankID, BankID på mobil,
							Epost, eller Feide.
						</p>
					)}
					{IS_NORMING_VERSION &&
						strings.getLanguage() !== 'de' &&
						strings.getLanguage() !== 'se' && (
							<p>
								Siden dette er en normeringstest er det ikke
								påkrevd at du logger inn med en autorisert
								påloggingsmetode. Dersom du har en BankID eller
								feide aktivert for Literate sine tester bør du
								bruke disse. Om ikke, velg "ingen innlogging"
							</p>
						)}
					{showLoginButtons && !this.state.pincodeLogin && (
						<div className="bank-id-type-selector-wrapper">
							{loginMethods[LANGUAGE].includes(BANK_ID) && (
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(BANK_ID, testKey);
									}}
								>
									BankID
								</button>
							)}
							{loginMethods[LANGUAGE].includes(
								BANK_ID_MOBILE
							) && (
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(BANK_ID_MOBILE, testKey);
									}}
								>
									BankID på Mobil
								</button>
							)}

							{loginMethods[LANGUAGE].includes(FEIDE) && (
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(FEIDE, testKey);
									}}
								>
									Feide
								</button>
							)}
							{loginMethods[LANGUAGE].includes(PINCODE) &&
								strings.getLanguage() !== 'de' &&
								strings.getLanguage() !== 'se' && (
									<button
										className="lit-btn lit-btn--small bg-secondary"
										onClick={() => {
											this.setState({
												pincodeLogin: true,
											});
										}}
									>
										Epost
									</button>
								)}
							{IS_NORMING_VERSION &&
								strings.getLanguage() !== 'de' &&
								strings.getLanguage() !== 'se' && (
									<>
										<br />
										<br />
										<button
											className="lit-btn lit-btn--small bg-secondary"
											onClick={() => {
												this.initializeAltLogin();
											}}
										>
											Ingen innlogging
										</button>
									</>
								)}
						</div>
					)}
					{showIframe && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									height: '600px',
									width: '100%',
								}}
							/>
						</div>
					)}
					{this.state.pincodeLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin />
						</div>
					)}
					{showError && <div>{error}</div>}
					{showAltLogin && (
						<div className="alternate-login">
							<p>
								For å logge inn uten BankID eller Feide trenger
								vi å vite hva du heter
							</p>
							<form onSubmit={this.submitAltLogin}>
								<label>
									Fornavn
									<input
										type="text"
										name="firstName"
										id="firstName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								<label>
									Etternavn
									<input
										type="text"
										name="lastName"
										id="lastName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								{altError && (
									<p className="error">{altError}</p>
								)}
								<button
									className="lit-btn lit-btn--small"
									type="submit"
								>
									Logg inn
								</button>
							</form>
						</div>
					)}
				</div>
				{altError && (
									<p className="error" style={{width:'20%',margin:'auto',color:'yellow'}}>{altError}</p>
								)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
