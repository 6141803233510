import audio2 from '../../../../assets/sounds/child/de/SCR-C-047.mp3';
import audio3 from '../../../../assets/sounds/child/de/SCR-C-048.mp3';
import audio4 from '../../../../assets/sounds/child/de/SCR-C-049.mp3';

import trial1 from '../../../../assets/sounds/child/de/Trial1_wordchain.mp3';
import trial2 from '../../../../assets/sounds/child/de/Trial2_wordchain.mp3';
import trial3 from '../../../../assets/sounds/child/de/Trial3_wordchain.mp3';
export const assignments = [
	{
		
		id: 1,
		text: 'HUNDLAUFENOBENDU',
		answer: 'HUND LAUFEN OBEN DU',
		audio: audio2,
		hasAnsweredFalsely:false
		/*count: 'andre',
		hasPlayed: false, */
	},
	{
		id: 2,
		text: 'LESENSAUBERTISCHDIE',
		answer: 'LESEN SAUBER TISCH DIE',
		audio: audio2,
		hasAnsweredFalsely:false
		/* count: 'tredje',
		hasPlayed: false, */
	},
	{
		id: 3,
		text: 'INTIEFZIMMERESSEN',
		answer: 'IN TIEF ZIMMER ESSEN',
		audio: audio2,
		hasAnsweredFalsely:false,
		count: 'tredje',
		hasPlayed: false,
	},
];


export const assignments1 = [
	{
		mainText:'Jetzt kommt das erste Beispiel!',
		mainSound:trial1,
		id: 1,
		text: 'HUNDLAUFENOBENDU',
		answer: 'HUND LAUFEN OBEN DU',
		audio: audio2,
		hasAnsweredFalsely:false,
		/*count: 'andre',
		hasPlayed: false, */
	},
	
];

export const assignments2 = [
	{
		mainText:'Jetzt kommt das zweite Beispiel!',
		mainSound:trial2,
		id: 2,
		text: 'LESENSAUBERTISCHDIE',
		answer: 'LESEN SAUBER TISCH DIE',
		audio: audio3,
		hasAnsweredFalsely:false,
		/* count: 'tredje',
		hasPlayed: false, */
	},
];

export const assignments3 = [
	{
		mainText:'Jetzt kommt das dritte Beispiel!',
		mainSound:trial3,
		hasAnsweredFalsely:false,
		id: 3,
		text: 'INTIEFZIMMERESSEN',
		answer: 'IN TIEF ZIMMER ESSEN',
		audio: audio4,
		count: 'tredje',
		hasPlayed: false,
	},
];

export default assignments;
