import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';
import audioNo from '../../assets/sounds/child/de/byttelyd-Intro.mp3';
import audioNn from '../../assets/sounds/child/de/byttelyd-Intro.mp3';
import videoBiltoPil from './assets/video_byttelyd.mp4';
const languageObj = {
	no: audioNo,
	nn: audioNn,
	de: audioNn,
};

class Intro extends React.Component {
	constructor(props) {
		super(props);
		const language = strings.getLanguage();
		this.currentLang = language;
		this.audio = languageObj[strings.getLanguage()];
		this.video = videoBiltoPil;
		this.videoRefView = React.createRef();
	}

	render() {
		const {onNextStep} = this.props;
		let videoRef;
		return (
			<div>

				<p>
					<AudioPlayer
						autoplay
						inline
						onStart={() => {
								const scrollActionTime =
									this.currentLang === 'de' ? 36200 : null;

								if (scrollActionTime) {
									setTimeout(() => {
										if (this.videoRefView.current) {
											this.videoRefView.current.scrollIntoView(
												{
													behavior: 'smooth',
													block: 'start',
												}
											);
											videoRef.play();
										}
										
									}, scrollActionTime);
								}
							}}
						onPause={() => {
							videoRef.play();
						}}
						onComplete={() => {
							videoRef.play();
							
						}}
						
						
						src={this.audio}
					/>
					{strings.tulleordtesteneksempel}
				</p>
				<br/>
				<p>{strings.formatString(
						strings.tulleordtesteneksempel1,
						<span><strong style={{color:'cyan'}}>L</strong>amm</span>,
						<span><strong style={{color:'cyan'}}>l</strong></span>,
						<span><strong style={{color:'orange'}}>k</strong></span>,
						<span><strong style={{color:'orange'}}>K</strong>amm</span>,
					)}</p>
				<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{strings.testLooksLikeThis}</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
				<p>{strings.tulleordtestenPractice}</p>
				<p>{strings.subtestStarterTrial}</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => {
						this.props.onShowAvatar(false);
						onNextStep();
					}}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
