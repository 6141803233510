import audio1 from '../../../../assets/sounds/child/de/diktat/trial1.mp3';
import audio2 from '../../../../assets/sounds/child/de/diktat/trial2.mp3';
import audio3 from '../../../../assets/sounds/child/de/diktat/trial3.mp3';

import mid1 from '../../../../assets/sounds/child/de/diktat/middleGrades/trial1.mp3';
import mid2 from '../../../../assets/sounds/child/de/diktat/middleGrades/trial2.mp3';
import mid3 from '../../../../assets/sounds/child/de/diktat/middleGrades/trial3.mp3';

import upper1 from '../../../../assets/sounds/child/de/diktat/upperGrades/trial1.mp3';
import upper2 from '../../../../assets/sounds/child/de/diktat/upperGrades/trial2.mp3';
import upper3 from '../../../../assets/sounds/child/de/diktat/upperGrades/trial3.mp3';

export const audioFiles_grade2 = {
	1: audio1,
	2: audio2,
	3: audio3,
};
export const audioFiles_grade3 = {
	1: mid1,
	2: mid2,
	3: mid3
};

export const audioFiles_grade4 = audioFiles_grade3;

export const audioFiles_grade5 = {
	1: upper1,
	2: upper2,
	3: upper3
};

export const audioFiles_grade6 = audioFiles_grade5;
export const audioFiles_grade7 = audioFiles_grade5;
export const audioFiles_grade8 = audioFiles_grade5;