import React from 'react';

export const TextGrade2 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Der rote Blitz`}</h2>
				<p>
					{`Ida lebt in einem Haus am Wald.  Das Haus ist von einem schönen Garten 
					umgeben. An diesem regnerischen Nachmittag sieht der Garten aber leer und 
					unheimlich aus. Ida seufzt. Sie darf nicht draußen spielen. Sie soll ihr Zimmer 
					aufräumen. Wie langweilig das ist! 
					`}
				</p>
				<br/>
		<p>{`Ida sitzt auf ihrem Bett und starrt aus dem Fenster. Plötzlich bemerkt sie ein rotes 
				Leuchten im Garten. Neugierig schaut sie genauer hin. Etwas bewegt sich schnell 
				durch die Büsche. Ida tritt näher ans Fenster. Ihr Herz pocht vor Aufregung. Ein 
				flinker Fuchs! Ida schreckt zurück. Ein Fuchs ist doch ein wildes Tier und frisst 
				andere Tiere. Ist das nicht gefährlich? Und nun steht er in ihrem Garten. Dort 
				hätte Ida bei schönem Wetter jetzt sicher Fußball gespielt. `}</p>
					<br/>
				<p>{`
				Vorsichtig geht Ida noch näher ans Fenster. Sie stellt sich auf ihre Zehenspitzen, 
				um den Fuchs besser zu beobachten. Plötzlich dreht er seinen Kopf zu ihr. Er 
				schaut ihr direkt in die Augen. Ida erstarrt vor Schreck. Sie hält den Atem an. Da 
				saust der Fuchs schon wie ein roter Blitz durch die Hecke und ist verschwunden. 
				Ida denkt: „Wir haben uns wohl beide erschrocken. Wahrscheinlich sind Füchse 
				doch nicht so gefährlich. Vielleicht kommt er mich beim nächsten Regen wieder 
				besuchen.“`}</p>
			</span>
		),
		questions: [
			{
				id: '1',
				text: 'Wo wohnt Ida? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'in der Nähe des Waldes',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'in einem Dorf',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'in einer Stadt',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'an einem See',
					},
				],
			},
			{
				id: '2',
				text: 'Welches Tier sieht Ida im Garten? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'einen Wolf',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'eine Katze',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'einen Bären',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'einen Fuchs',
					},
				],
			},

			{
				id: '3',
				text: 'Warum ist Ida gelangweilt? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Der Garten sieht leer und unheimlich aus.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Sie soll ihr Zimmer aufräumen.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Es regnet.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Sie kann nicht mit ihren Freunden spielen.',
					},
				],
			},
			{
				id: '4',
				text: 'Warum ist Ida am Ende erleichtert? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Der Fuchs ist wahrscheinlich gar nicht so gefährlich.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Ida darf jetzt wieder draußen spielen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Der Fuchs kommt nur bei Regen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Ida hat ihr Zimmer aufgeräumt.',
					},
				],
			},
			{
				id: '5',
				text: ' Warum sieht der Fuchs wie ein roter Blitz aus? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er ist laut.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Er kommt mit dem Regen.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Er ist gefährlich.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'Er ist sehr schnell.',
					},
				],
			},
			{
				id: '6',
				text: 'Warum ist Ida am Anfang besorgt? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Sie stellt sich vor, dass der Fuchs auch im Garten ist, wenn sie dort spielt.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sie hat Angst vor dem Gewitter.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Der Fuchs hat sich verlaufen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Der Fuchs schaut ihr direkt in die Augen. ',
					},
				],
			},
		],
	},
];

export const TextGrade3 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Pauli geht alleine spazieren`}</h2>
				<p>
					{`Normalerweise liebt Nele es, in die Schule zu gehen. Besonders gerne lernt sie 
etwas über die Tiere und Pflanzen des Waldes oder spielt in der Pause fröhlich 
Fußball mit ihren Freundinnen. Doch an diesem Tag kann sie sich einfach nicht 
konzentrieren. Nicht einmal in ihrem Lieblingsfach Deutsch kann sie still sitzen 
bleiben. Ständig schaut sie auf die Uhr. Sie will endlich nach Hause.
					`}
				</p>
				<br/>
		<p>{`Neles Familie hat nämlich Zuwachs bekommen: einen kleinen Welpen namens 
Pauli. Pauli ist weiß und so schnell wie eine Rakete. Am liebsten tobt Nele mit ihm 
durch den Garten und wirft Stöckchen, die er ihr dann stolz zurückbringt. Da das 
Spiel manchmal noch nicht so gut gelingt, üben sie viel.   `}</p>
					<br/>
				<p>{`
				Als Nele nach Hause kommt, erwartet sie dort jedoch eine schlechte Nachricht. 
				Ihr Vater sagt mit besorgter Stimme: „Pauli ist weggelaufen. Das Gartentor war 
				offen. Deine Schwester ist schon los, um ihn zu suchen. Kannst Du ihr helfen?“`}</p>
				<br/>
		<p>{`Nele hat große Angst um Pauli. Sie läuft auf die Straße und ruft nach ihm. „Wenn 
ich laufe, bin ich viel zu langsam“, denkt sie verzweifelt. Dann fällt ihr ein, dass sie 
mit dem Fahrrad viel schneller sein könnte. Sie rennt zurück zum Schuppen, 
schwingt sich auf ihr rotes Fahrrad und tritt in die Pedale. Nun fährt Nele durch 
die Straßen des Dorfes und ruft nach Pauli. Dabei entfernt sie sich immer weiter 
von zuhause. Schließlich ist sie fast am Wald angekommen und beginnt die 
Hoffnung zu verlieren. Doch da sieht sie plötzlich zwischen den Büschen einen 
weißen Puschel. „Pauli!“, ruft Nele glücklich und fährt schneller, um ihren kleinen 
Freund zu retten. `}</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Was ist Neles Lieblingsfach? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Deutsch',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Mathematik',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Sie hat kein Lieblingsfach.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sachunterricht',
					},
				],
			},
			{
				id: 2,
				text: 'Wie konnte Pauli entkommen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Nele hat nicht aufgepasst.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Das Gartentor war offen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Pauli war nicht angeleint.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pauli war alleine im Garten.',
					},
				],
			},
			{
				id: 3,
				text: 'Was ist an dem heutigen Schultag anders? ',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'Nele kann es nicht erwarten, nach Hause zu kommen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Nele lernt nicht gerne über die Tiere des Waldes.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Nele darf kein Fußball spielen.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Nele ist krank.',
					},
				],
			},
			{
				id: 4,
				text: 'Warum beschließt Nele, mit dem Fahrrad auf die Suche zu gehen? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Pauli ist sehr schnell und zu Fuß kann Nele ihn nicht einholen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Pauli versteckt sich in der Nähe des Waldes. ',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mit dem Fahrrad macht die Suche mehr Spaß.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Ihre Schwester ist schon zu Fuß unterwegs.',
					},
				],
			},
			{
				id: 5,
				text: 'Wie gut kann Pauli schon Stöckchen bringen? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Pauli bringt das Stöckchen immer.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Pauli bringt das Stöckchen meistens.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Pauli bringt das Stöckchen nie.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pauli spielt überhaupt nicht mit dem Stöckchen.',
					},
				],
			},
			{
				id: 6,
				text: ' Warum beginnt Nele, die Hoffnung zu verlieren, als sie den Wald erreicht? ',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Der Wald ist schon Teil des Nachbardorfes.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Die Schwester hat Pauli nicht gefunden. ',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'Je weiter sich Nele von zuhause entfernt, desto schwieriger ist es, Pauli zu finden.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie ist sich sicher, dass Pauli nicht im Wald sein kann.',
					},
				],
			},
		],
	},
];
export const TextGrade4 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Alex und der Waldgeist`}</h2>
				<p>
					{`
			Auf dem Weg zur Schule kommt Alex immer an einem kleinen Wäldchen vorbei. 
			Manchmal sieht er etwas durch das Geäst huschen und hat das Gefühl, als 
			beobachten ihn zwei große Augen. Das findet Alex unheimlich und er läuft dann 
			immer ein bisschen schneller. Eines Morgens, als Alex wieder auf dem Weg zur 
			Schule ist, bemerkt er etwas Ungewöhnliches am Rand des Wäldchens: Jemand 
			hat einfach ein paar Säcke Müll dort abgeladen. „Wer macht denn so etwas?“,  
			denkt Alex empört und läuft weiter zur Schule. 
			`}
				</p>
				<br/>
		<p>{`
		Ein paar Tage vergehen, aber der Müll wurde noch nicht abgeholt. Inzwischen 
		sind die Säcke aufgeplatzt und überall liegt Plastik und Papier herum. Das ärgert 
		Alex sehr. Er macht sich Gedanken darüber, was mit den Tieren und Wesen im 
		Wäldchen passieren könnte. Der Müll muss sie doch stören! Also fasst Alex den 
		Entschluss, selbst einzuschreiten. Nach der Schule geht er schnell nach Hause 
		und macht sich anschließend erneut auf den Weg zum Wäldchen, einen 
		Bollerwagen aus dem Garten seiner Eltern und einige große Mülltüten im 
		Schlepptau. Er sammelt die verstreuten Plastik- und Papierteile auf und stopft sie 
		in die mitgebrachten Tüten. 
		`}</p>
		<br/>
		<p>{`
		Während er konzentriert arbeitet und darauf achtet, keinen Müll zu übersehen, 
		fühlt Alex sich erneut beobachtet. Es ist wieder dieses Gefühl, als säße etwas im 
		Dickicht und schaue ihm zu. Alex lässt sich jedoch nicht beirren und macht tapfer 
		weiter. Als aller Müll aufgesammelt und in den Tüten verstaut ist, lädt er diese auf 
		den Bollerwagen. Der Straßenrand am Wald ist nun wieder blitzeblank und kein 
		Müll ist mehr zu sehen.  `}</p>
		<br/>
		<p>{`
		Als Alex schon auf dem Heimweg ist, dreht er sich noch einmal um, um stolz sein 
		Werk zu betrachten. Plötzlich sieht er einen kleinen Waldgeist zwischen den 
		Bäumen hervorlugen. Der Waldgeist winkt ihm freundlich zu und wirkt sehr 
		zufrieden. Nun weiß Alex, wer ihn die ganze Zeit beobachtet hat!  `}</p>
		<br/>
		<p>{`
		Seitdem Alex den Müll beseitigt hat, fühlt er sich auf seinem Schulweg viel 
		besser. Statt des beklemmenden Gefühls, freut er sich nun über seine gute Tat 
		und über seinen neuen Freund, den kleinen Waldgeist.`}</p>
		<br/>
		<p>{``}</p>
			</span>
		),

		questions: [
			{
				id: 1,
				text: ' Wohin packt Alex den eingesammelten Müll?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'in seinen Bollerwagen.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'in seine Tasche.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'in den Garten seiner Eltern.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'an den Waldrand.',
					},
				],
			},
			{
				id: 2,
				text: 'Wie wirkt der Waldgeist, als er sich Alex das erste Mal zeigt? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'glücklich und zufrieden',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fröhlich und lustig',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'unheimlich',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'freundlich und zufrieden',
					},
				],
			},
			{
				id: 3,
				text: 'Wie fühlt sich Alex, als er den Müll am Waldrand entdeckt? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er hat Angst.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er ist besorgt.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Er ist verwirrt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Er ist verärgert.',
					},
				],
			},
			{
				id: 4,
				text: 'Warum entschließt Alex sich, selbst den Müll wegzuräumen? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Weil der Müll auch Tage später noch nicht entsorgt wurde.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Weil seine Eltern ihm nicht helfen wollen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Weil das Wäldchen auf seinem Schulweg liegt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Weil Alex Mülltüten und einen Bollerwagen im Garten seiner Eltern hat.',
					},
				],
			},
			{
				id: 5,
				text: 'Warum möchte Alex den Müll wegräumen? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er mag keinen Müll in der Natur.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er macht sich Sorgen um die Tiere und Wesen des Waldes.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Der Wald wirkt so weniger bedrohlich.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Es gibt Müllsäcke im Garten seiner Eltern.',
					},
				],
			},
			{
				id: 6,
				text: 'Warum zeigt sich der Waldgeist, als sich Alex auf seinem Heimweg noch einmal umdreht?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er ist dankbar dafür, dass Alex den Müll weggeräumt hat.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er möchte Alex erschrecken. ',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Er möchte Alex keine Angst mehr machen. ',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Er will sich von Alex verabschieden. ',
					},
				],
			},
		],
	},
];

export const TextGrade5 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Pauli geht alleine spazieren`}</h2>
				<p>
					{`Eigentlich liebt es Nele, in die Schule zu gehen. Besonders gerne lernt sie im 
Biologieunterricht etwas über die Tiere und Pflanzen des Waldes oder spielt in der Pause 
ausgelassen Fußball mit ihren Freundinnen. Doch an diesem Tag kann sie sich einfach 
nicht konzentrieren, nicht einmal in ihrem Lieblingsfach Deutsch kann sie still sitzen 
bleiben! Sehnsüchtig schaut sie auf die Uhr, denn sie kann es nicht abwarten, endlich 
nach Hause zu kommen. `}
				</p>
				<br/>
				<p>
					{`Neles Familie hat nämlich Zuwachs bekommen: Ein kleiner Welpe namens Pauli ist bei 
ihnen eingezogen. Pauli ist weiß wie eine Schneeflocke, ganz flauschig und so schnell 
wie eine Rakete. Außerdem ist er auch sehr frech und hat nur Dummheiten im Kopf! Am 
liebsten tobt Nele mit ihm durch den Garten und wirft Stöckchen, die er ihr dann stolz 
zurückbringt. Da das Spiel manchmal noch nicht so gut gelingt, üben sie viel.`}
				</p>
				<br/>
				<p>
					{`Als Nele endlich von der Schule nach Hause kommt, erwartet sie dort jedoch eine 
beunruhigende Nachricht. Ihr Vater steht niederschlagen in der Küche und stammelt mit 
bedrückter Stimme: „Pauli ist weg. Wir waren alle draußen im Garten und haben kurz 
nicht aufgepasst, da ist er einfach durch das offene Tor geschlüpft. Das muss wohl vor 
einer halben Stunde gewesen sein. Deine Schwester und dein Bruder sind schon 
losgelaufen, um ihn zu suchen. Kannst Du ihnen helfen?“`}
				</p>
				<br/>
				<p>
					{`Nele hat große Angst um ihren hilflosen kleinen Welpen. Er ist doch noch so verspielt 
und unbedacht, sie möchte sich gar nicht ausmalen, was alles passieren könnte! 
Besorgt durchstreift sie die naheliegenden Straßen, aber Pauli ist nirgends zu sehen. Ein 
paar Ecken weiter trifft sie auf ihre Geschwister, aber auch deren Suche ist bis jetzt 
erfolglos geblieben. Das Ganze kommt Nele wenig effektiv vor, immerhin ist Pauli schon 
seit längerer Zeit weg. So ein stürmischer und flinker Hund ist in der Zeit doch schon 
über alle Berge.`}
				</p>
				<br/>
				<p>
					{`„Wenn ich laufe, bin ich viel zu langsam“, denkt sie verzweifelt. Auf diese Weise kann sie 
Pauli wohl kaum einholen. Doch dann kommt ihr ein cleverer Einfall: Mit dem Fahrrad 
könnte sie viel schneller sein und auch entferntere Teile der Ortschaft zügig erreichen. In 
Windeseile kehrt sie zurück zum Schuppen, schwingt sich auf ihr rotes Fahrrad und tritt 
ordentlich in die Pedale. Rufend fährt sie nun durch die Straßen des Dorfes. Dabei 
kommt sie auch an die entlegensten Stellen und entfernt sich immer weiter von 
zuhause. Schließlich ist sie schon fast am Wald angekommen und beginnt, die Hoffnung 
zu verlieren. Doch auf einmal erblickt sie am Ende der Sandstraße einen weißen Puschel 
zwischen den Büschen. „Pauli!“, ruft Nele überglücklich und fährt noch einmal 
schneller, um ihren kleinen Freund endlich zu retten.`}
				</p>
			
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Was ist Neles Lieblingsfach?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Deutsch',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Mathematik',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Sie hat kein Lieblingsfach.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sachunterricht',
					},
				],
			},
			{
				id: 2,
				text: 'Wie konnte Pauli entkommen? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Nele hat nicht aufgepasst.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Das Gartentor war offen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Pauli war nicht angeleint.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pauli war alleine im Garten.',
					},
				],
			},
			{
				id: 3,
				text: 'Was ist an dem heutigen Schultag anders? ',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'Nele kann es nicht erwarten, nach Hause zu kommen.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Nele lernt nicht gerne über die Tiere des Waldes.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Nele darf kein Fußball spielen.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Nele ist gerne im Deutschunterricht.',
					},
				],
			},
			{
				id: 4,
				text: 'Warum beschließt Nele, mit dem Fahrrad auf die Suche zu gehen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Pauli ist sehr schnell und zu Fuß kann Nele ihn nicht einholen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Pauli versteckt sich in der Nähe des Waldes.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mit dem Fahrrad macht die Suche mehr Spaß.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Ihre Geschwister sind schon zu Fuß unterwegs. ',
					},
				],
			},
			{
				id: 5,
				text: 'Wie gut kann Pauli schon Stöckchen bringen?  ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Pauli bringt das Stöckchen immer.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Pauli bringt das Stöckchen meistens.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Pauli bringt das Stöckchen nie.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pauli spielt überhaupt nicht mit dem Stöckchen.',
					},
				],
			},
			{
				id: 6,
				text: 'Warum beginnt Nele, die Hoffnung zu verlieren, als sie den Wald erreicht?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Der Wald ist schon Teil des Nachbardorfes.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Ihre Geschwister haben Pauli nicht gefunden.',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'Je weiter sich Nele von zuhause entfernt, desto unwahrscheinlicher ist es, Pauli zu finden.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie ist sich sicher, dass Pauli nicht im Wald sein kann.',
					},
				],
			},
		],
	},
	
];
export const TextGrade6 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Der rote Blitz`}</h2>
				<br/>
				<p>
					{`Antonia lebt mit ihrer Familie in einem gemütlichen Haus am Rande des Waldes. 
Ihr Zuhause ist von einem schönen Garten umgeben, der an diesem regnerischen 
Nachmittag jedoch leer und unheimlich aussieht. Antonia seufzt, denn sie wurde 
dazu verdonnert, ihr Zimmer aufzuräumen – erst dann darf sie wieder rausgehen, 
um zu spielen. Das ist so ungerecht, immerhin sieht ihr Zimmer doch schon recht 
ordentlich aus – findet Antonia zumindest. Wie langweilig das Aufräumen doch 
ist! `}
				</p>
				<br/>
				<p>
					{`Nun sitzt Antonia also mürrisch auf ihrem Bett und starrt gelangweilt an die 
Wand. Während sie vor sich hin grübelt, bemerkt sie plötzlich im Augenwinkel ein 
kurzes rotes Leuchten draußen im Garten. Was kann das nur gewesen sein? 
Neugierig schaut sie genauer hin und entdeckt etwas, das geschwind durch die 
Büsche prescht. Voller Anspannung steht Antonia vom Bett auf. Als sie näher ans 
Fenster tritt, pocht ihr Herz vor Aufregung. Schließlich kann sie ihn ausmachen, 
einen flinken Fuchs! Antonia schreckt zurück, denn obwohl sie sicher ist in ihrem 
Zimmer, weiß sie, dass Füchse wilde Tiere sind, die andere Tiere jagen und 
fressen. Ist das nicht auch gefährlich für sie? Ein Fuchs in ihrem Garten, in dem 
sie bei schönem Wetter jetzt Fußball gespielt hätte?`}
				</p>
				<br/>
				<p>
					{`Gebückt und mit gespitzten Ohren rennt der Fuchs durch den dunklen Garten. 
Seine bernsteinfarbenen Augen suchen aufmerksam das Terrain ab. 
Möglicherweise geht er in den am Wald angrenzenden Gärten auf die Jagd. 
Antonia ist verblüfft von der Größe des wilden Tieres – sie hatte Füchse als 
schwächlicher in Erinnerung. Er ist auf jeden Fall weitaus angsteinflößender als 
andere Tiere, die sie bis jetzt in freier Wildbahn gesehen hat. So einem Raubtier 
möchte sie ganz bestimmt nicht alleine im Wald begegnen – und vor allem nicht 
in ihrem eigenen Garten! `}
				</p>
				<br/>
				<p>
					{`Schließlich bleibt der Fuchs unmittelbar vor der Hausfassade stehen. Vorsichtig 
nähert sich Antonia noch mehr der Fensterscheibe und stellt sich schließlich 
langsam auf ihre Zehenspitzen, um das Geschehen besser beobachten zu 
können. Diese Bewegung war allerdings zu viel: Plötzlich dreht der Fuchs seinen 
Kopf zu ihr und schaut ihr direkt in die Augen, woraufhin Antonia vor Schreck 
erstarrt und den Atem anhält. Doch dann geschieht etwas Unerwartetes: In 
Windeseile dreht sich der Fuchs um, saust wie ein roter Blitz durch die Hecke und 
ist verschwunden.`}
				</p>
				<br/>
				<p>
					{`Erleichtert atmet Antonia auf. Das Tier hatte sie anscheinend hinter der 
Fensterscheibe erkannt und aus diesem Grund die Flucht ergriffen. „Wir haben 
uns wohl beide voreinander erschrocken“, reflektiert Antonia. Vielleicht war die 
Situation tatsächlich gar nicht so brenzlig gewesen. Ihre Biologielehrerin hatte 
einmal erklärt, dass wilde Tiere eigentlich noch mehr Angst vor den Menschen 
hätten als umgekehrt. „Wahrscheinlich sind Füchse doch nicht so gefährlich“, 
denkt sich Antonia, „beim nächsten Regen halte ich wieder Ausschau, vielleicht 
kommt der Fuchs mich dann noch einmal besuchen.“`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Warum ist Antonia gelangweilt?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Der Garten sieht leer und unheimlich aus. ',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Sie soll ihr Zimmer aufräumen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Es regnet.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie kann nicht mit ihren Freunden spielen.',
					},
				],
			},
			{
				id: 2,
				text: 'Was tut Antonia in dem Moment, als sie das rote Leuchten bemerkt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Sie schaut aus dem Fenster.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Sie räumt ihr Zimmer auf.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Sie schaut die Wand an.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie sitzt am Schreibtisch.',
					},
				],
			},
			{
				id: 3,
				text: 'Warum rennt der Fuchs weg? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: ' Er hat Antonia gehört und Angst bekommen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er darf eigentlich nicht im Garten sein.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Antonia hat ihn bedrängt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Er hat Antonia am Fenster gesehen und Angst bekommen.',
					},
				],
			},
			{
				id: 4,
				text: 'Warum ist Antonia am Ende erleichtert?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Der Fuchs ist wahrscheinlich gar nicht so gefährlich.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Der Fuchs ist endlich wieder weg.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Der Fuchs kommt nur bei Regen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Antonoia hat ihr Zimmer aufgeräumt.',
					},
				],
			},
			{
				id: 5,
				text: 'Warum sieht der Fuchs wie ein roter Blitz aus?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er ist laut.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Er kommt mit dem Regen.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Er ist gefährlich.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'Er ist sehr schnell.',
					},
				],
			},
			{
				id: 6,
				text: 'Warum ist Antonia am Anfang besorgt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Möglicherweise ist das wilde Tier auch im Garten, wenn Antonia dort spielt.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sie hat Angst vor Füchsen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Füchse jagen andere Tiere.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie ist überrascht von der Größe des Raubtiers.',
					},
				],
			},
		],
	},
];
export const TextGrade7 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`SPRELSKE SPØKELSER`}</h2>
				<br/>
				<p>
					{`Antonia lebt mit ihrer Familie in einem gemütlichen Haus am Rande des Waldes. 
Ihr Zuhause ist von einem schönen Garten umgeben, der an diesem regnerischen 
Nachmittag jedoch leer und unheimlich aussieht. Antonia seufzt, denn sie wurde 
dazu verdonnert, ihr Zimmer aufzuräumen – erst dann darf sie wieder rausgehen, 
um zu spielen. Das ist so ungerecht, immerhin sieht ihr Zimmer doch schon recht 
ordentlich aus – findet Antonia zumindest. Wie langweilig das Aufräumen doch 
ist! `}
				</p>
				<br/>
				<p>
					{`Nun sitzt Antonia also mürrisch auf ihrem Bett und starrt gelangweilt an die 
Wand. Während sie vor sich hin grübelt, bemerkt sie plötzlich im Augenwinkel ein 
kurzes rotes Leuchten draußen im Garten. Was kann das nur gewesen sein? 
Neugierig schaut sie genauer hin und entdeckt etwas, das geschwind durch die 
Büsche prescht. Voller Anspannung steht Antonia vom Bett auf. Als sie näher ans 
Fenster tritt, pocht ihr Herz vor Aufregung. Schließlich kann sie ihn ausmachen, 
einen flinken Fuchs! Antonia schreckt zurück, denn obwohl sie sicher ist in ihrem 
Zimmer, weiß sie, dass Füchse wilde Tiere sind, die andere Tiere jagen und 
fressen. Ist das nicht auch gefährlich für sie? Ein Fuchs in ihrem Garten, in dem 
sie bei schönem Wetter jetzt Fußball gespielt hätte?`}
				</p>
				<br/>
				<p>
					{`Gebückt und mit gespitzten Ohren rennt der Fuchs durch den dunklen Garten. 
Seine bernsteinfarbenen Augen suchen aufmerksam das Terrain ab. 
Möglicherweise geht er in den am Wald angrenzenden Gärten auf die Jagd. 
Antonia ist verblüfft von der Größe des wilden Tieres – sie hatte Füchse als 
schwächlicher in Erinnerung. Er ist auf jeden Fall weitaus angsteinflößender als 
andere Tiere, die sie bis jetzt in freier Wildbahn gesehen hat. So einem Raubtier 
möchte sie ganz bestimmt nicht alleine im Wald begegnen – und vor allem nicht 
in ihrem eigenen Garten! `}
				</p>
				<br/>
				<p>
					{`Schließlich bleibt der Fuchs unmittelbar vor der Hausfassade stehen. Vorsichtig 
nähert sich Antonia noch mehr der Fensterscheibe und stellt sich schließlich 
langsam auf ihre Zehenspitzen, um das Geschehen besser beobachten zu 
können. Diese Bewegung war allerdings zu viel: Plötzlich dreht der Fuchs seinen 
Kopf zu ihr und schaut ihr direkt in die Augen, woraufhin Antonia vor Schreck 
erstarrt und den Atem anhält. Doch dann geschieht etwas Unerwartetes: In 
Windeseile dreht sich der Fuchs um, saust wie ein roter Blitz durch die Hecke und 
ist verschwunden.`}
				</p>
				<br/>
				<p>
					{`Erleichtert atmet Antonia auf. Das Tier hatte sie anscheinend hinter der 
Fensterscheibe erkannt und aus diesem Grund die Flucht ergriffen. "Wir haben 
uns wohl beide voreinander erschrocken“, reflektiert Antonia. Vielleicht war die 
Situation tatsächlich gar nicht so brenzlig gewesen. Ihre Biologielehrerin hatte 
einmal erklärt, dass wilde Tiere eigentlich noch mehr Angst vor den Menschen 
hätten als umgekehrt. „Wahrscheinlich sind Füchse doch nicht so gefährlich“, 
denkt sich Antonia, „beim nächsten Regen halte ich wieder Ausschau, vielleicht 
kommt der Fuchs mich dann noch einmal besuchen.“`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Warum ist Antonia gelangweilt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Der Garten sieht leer und unheimlich aus. ',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sie soll ihr Zimmer aufräumen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Es regnet.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie kann nicht mit ihren Freunden spielen.',
					},
				],
			},
			{
				id: 2,
				text: 'Was tut Antonia in dem Moment, als sie das rote Leuchten bemerkt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Sie schaut aus dem Fenster.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sie räumt ihr Zimmer auf.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Sie schaut die Wand an.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie sitzt am Schreibtisch.',
					},
				],
			},
			{
				id: 3,
				text: 'Warum rennt der Fuchs weg? ',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: ' Er hat Antonia gehört und Angst bekommen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er darf eigentlich nicht im Garten sein.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Antonia hat ihn bedrängt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Er hat Antonia am Fenster gesehen und Angst bekommen.',
					},
				],
			},
			{
				id: 4,
				text: 'Warum ist Antonia am Ende erleichtert?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Der Fuchs ist wahrscheinlich gar nicht so gefährlich.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Der Fuchs ist endlich wieder weg.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Der Fuchs kommt nur bei Regen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Antonoia hat ihr Zimmer aufgeräumt.',
					},
				],
			},
			{
				id: 5,
				text: 'Warum sieht der Fuchs wie ein roter Blitz aus?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Er ist laut.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Er kommt mit dem Regen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Er ist gefährlich.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Er ist sehr schnell.',
					},
				],
			},
			{
				id: 6,
				text: 'Warum ist Antonia am Anfang besorgt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Möglicherweise ist das wilde Tier auch im Garten, wenn Antonia dort spielt.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sie hat Angst vor Füchsen.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Füchse jagen andere Tiere.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sie ist überrascht von der Größe des Raubtiers.',
					},
				],
			},
		],
	},
];

