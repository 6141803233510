import React from 'react';
import audio1 from '../../../../assets/sounds/child/de/word/01.mp3';
import incorrect1 from '../../../../assets/sounds/child/de/word/incorrect1.mp3';
import correct1 from '../../../../assets/sounds/child/de/word/correct1.mp3';
import audio2 from '../../../../assets/sounds/child/de/word/02.mp3';
import incorrect2 from '../../../../assets/sounds/child/de/word/incorrect2.mp3';
import correct2 from '../../../../assets/sounds/child/de/word/correct2.mp3';
import incorrect3 from '../../../../assets/sounds/child/de/word/incorrect3.mp3';
import correct3 from '../../../../assets/sounds/child/de/word/correct3.mp3';
import starterTesten from '../../../../assets/sounds/child/de/byttelydStartingtest.mp3';


import audio3 from '../../../../assets/sounds/child/de/word/03.mp3';

export const starterTestenSound = starterTesten;

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'Lys',
		},
		{
			word: 'Lese',
		},
		{
			word: 'Katt',
		},
		{
			isCorrect: true,
			word: 'Kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
	
		mainSound: audio1,
		text: <span style={{color:'cyan', margin:'auto', fontWeight:'bold', fontSize:'22px'}}> Stock </span>,
		choices: [
			{
				remark: false,
				word: 'Blatt',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Stab</span> </span>,
				sound: incorrect1,
			
			},
			{
				remark: true,
				word: 'Stab',
				comment: `Super, du hast die Reihe richtig gelöst!`,
				sound: correct1,
			
			},
			{
				remark: false,
				word: 'Baum',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Stab</span> </span>,
				sound: incorrect1,
			
			},
			
			{
				remark: false,
				word: 'Zaun',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Stab</span> </span>,
				sound: incorrect1,
			
			},
		],
	},
];

export const assignment2 = [
	{
		id: 2,
	
		mainSound: audio2,
		text:<span style={{color:'cyan', margin:'auto', fontWeight:'bold', fontSize:'22px'}}> still </span>,
		choices: [
			{
				remark: true,
				word: 'leise',
				comment: `Super, du hast die Reihe richtig gelöst!`,
				sound: correct2,
				
			},
			{
				remark: false,
				word: 'langsam',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> leise</span> </span>,
				sound: incorrect2,
				
			},
			{
				remark: false,
				word: 'laut',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> leise</span> </span>,
				sound: incorrect2,
				
			},
			{
				remark: false,
				word: 'ernst',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> leise</span> </span>,
				sound: incorrect2,
				
			},
		],
	},
];

export const assignment3 = [
	{
		id: 3,
		mainSound: audio3,
		text:<span style={{color:'cyan', margin:'auto', fontWeight:'bold', fontSize:'22px'}}> Qualm </span>,
		choices: [
			{
				remark: false,
				word: 'Feuer',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
			
			},
			{
				remark: false,
				word: 'Hitze',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
				
			},
			{
				remark: true,
				word: 'Rauch',
				comment: `Super, du hast die Reihe richtig gelöst!`,
				sound: correct3,
				
			},
			{
				remark: false,
				word: 'Brand',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
				
			},
		],
	},
];
export const assignment4 = [
	{
		id: 3,
		mainSound: audio3,
		text:<span style={{color:'cyan', fontWeight:'bold', fontSize:'20px'}}> Qualm </span>,
		choices: [
			{
				remark: false,
				word: 'Feuer',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
			
			},
			{
				remark: false,
				word: 'Hitze',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
				
			},
			{
				remark: true,
				word: 'Rauch',
				comment: `Super, du hast die Reihe richtig gelöst!`,
				sound: correct3,
				
			},
			{
				remark: false,
				word: 'Brand',
				comment:<span> Das war nicht ganz richtig. Die Lösung ist: <span style={{color:'orange'}}> Rauch</span> </span>,
				sound: incorrect3,
				
			},
		],
	},
];
export const assignments = [
	{
		id: 2,
	
		choices: [
			{
				word: '',
				comment: '',
				
				
			},
			{
				word: '',
				comment: '',
				
				
			},
			{
				word: '',
				comment: '',
				
				
			},
			{
				word: '',
				comment: '',
				
				
			},
		],
	},
	{
		id: 3,
		
		choices: [
			{
				word: '',
				comment:'',
				
			},
			{
				word: '',
				comment:'',
				
			},
			{
				word: '',
				comment:'',
			},
			{
				word: 'knain',
				comment:'',
				
			},
		],
	},
	{
		id: 4,
	
		choices: [
			{
				word: '',
				comment:'',
			
			},
			{
				word: '',
				comment:'',
			},
			{
				word: '',
				comment:'',
			
			},

			{
				word: '',
				comment:'',
				
			},
		],
	},
	{
		id: 5,
		
		choices: [
			{
				word: '',
				comment:'',
			},
			{
				word: '',
				comment:'',
			},

			{
				word: '',
				comment:'',
			},
			{
				word: '',
				comment:'',
				
			},
		],
	},
]; 